import React from 'react';

import { HelpCenterRoutes } from '@App/paths';
import { UserTypeEnum } from '@Types';
import { useUserContext } from '@Contexts';
import { getHelpCenterURL } from '@Helpers/helpCenter';
import { HelpCenterCard } from './HelpCenterCard';

export const HelpCenterCardController = () => {
  const { userData } = useUserContext();
  if (!userData) {
    return <></>;
  }
  const userType = userData.user_type;
  const helpCenterURL = getHelpCenterURL({
    path: userType === UserTypeEnum.GROUP ? HelpCenterRoutes.GROUPS : HelpCenterRoutes.FUNDERS,
    user: userData,
  });

  return <HelpCenterCard userType={userType} helpCenterURL={helpCenterURL} />;
};
