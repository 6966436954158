import { Button, Link, Tile } from '@Components/common';

import styles from './FundingOpportunityCard.module.scss';
import { FundDetails, FundingProfileData, GroupEligibilityForFund, LocalAuthority } from '@Types';
import { Collapse, Grid } from '@material-ui/core';
import { Loader, OrganizationAvatar, StatusChip } from '@Components';
import {
  RiTeamFill,
  RiHandCoinFill,
  RiMapPin2Fill,
  RiCalendar2Fill,
  RiCheckboxCircleFill,
} from 'react-icons/ri';
import { Trans, useTranslation } from 'react-i18next';
import { FundCatchmentArea } from '@Components/FundCatchementArea/FundCatchmentArea';
import { getFundPath } from '@Helpers';
import { FundInformation } from './FundInformation';
import classnames from 'classnames';
import { useCallback, useState } from 'react';
import { GroupEligibilityInformation } from './GroupEligibilityInformation';
import { AxiosResponse } from 'axios';
import { formatDate } from '@Helpers/formatDate';

export interface FundingOpportunityCardProps {
  id: number;
  details: FundDetails;
  funding_profile: FundingProfileData;
  localAuthorities: LocalAuthority[];
  preview?: boolean;
  showGroupEligibilityData?: boolean;
  groupHasProjectMatched?: boolean;
  getGroupEligibilityForFund?: (fundId: number) => Promise<AxiosResponse<GroupEligibilityForFund>>;
  groupEligibilityForFundData?: GroupEligibilityForFund;
  isGroupEligibilityForFundDataLoading?: boolean;
}

export const FundingOpportunityCard = ({
  id,
  details,
  funding_profile,
  localAuthorities,
  preview,
  showGroupEligibilityData,
  groupHasProjectMatched,
  getGroupEligibilityForFund,
  groupEligibilityForFundData,
  isGroupEligibilityForFundDataLoading,
}: FundingOpportunityCardProps) => {
  const {
    name,
    maximum_grant_amount,
    catchment_area_type,
    radius,
    postcode,
    deadline_to,
    categories,
    group_types_to_support,
  } = details;
  const { avatar_image, name: funderName } = funding_profile;
  const { t } = useTranslation('fundingOpportunities');
  const today = new Date();
  const deadline = new Date(deadline_to);
  // Add a day to the deadline to make it midnight of the following morning
  // This means the closed will only display once the deadline day has completely passed
  deadline.setDate(deadline.getDate() + 1);
  const hasDeadlinePassed = today > deadline;
  const fundLocation = (
    <FundCatchmentArea
      catchmentAreaType={catchment_area_type}
      radius={radius}
      postcode={postcode}
      localAuthority={localAuthorities.find((la) => la.id === details.local_authority)?.name || ''}
    />
  );
  const fundInformation = [
    {
      title: t('grant_amount'),
      icon: <RiHandCoinFill size={12} />,
      text: t('common:whole_currency', { amount: maximum_grant_amount }),
    },
    {
      title: t('location'),
      icon: <RiMapPin2Fill size={12} />,
      text: fundLocation,
    },
    {
      title: t('deadline'),
      icon: <RiCalendar2Fill size={12} />,
      text: hasDeadlinePassed ? (
        <p className={styles.closed}>{t('closed')}</p>
      ) : (
        formatDate(deadline_to)
      ),
    },
  ];

  const [groupEligibilityDataBreakdownVisible, setGroupEligibilityDataBreakdownVisible] =
    useState(false);

  const handleViewGroupEligibilityData = useCallback(() => {
    // We only want to call the hook if the collapse wasn't open
    // This stops it calling if someone presses it to close the collapse which prevents an unneccessary call
    if (!groupEligibilityDataBreakdownVisible && getGroupEligibilityForFund) {
      getGroupEligibilityForFund(id);
    }
    setGroupEligibilityDataBreakdownVisible(
      (prevGroupEligibilityDataBreakdownVisible) => !prevGroupEligibilityDataBreakdownVisible,
    );
  }, [getGroupEligibilityForFund, groupEligibilityDataBreakdownVisible, id]);

  return (
    <Link to={getFundPath(id)} className={styles.link}>
      <Tile className={styles.container}>
        <Grid container spacing={1} direction="column">
          <Grid container spacing={1} className={styles.insideContainer}>
            <Grid
              item
              className={classnames(styles.topRowContainer, preview && styles.topRowPreview)}
            >
              <OrganizationAvatar avatarImage={avatar_image} className={styles.avatar}>
                {<RiTeamFill size={24} />}
              </OrganizationAvatar>
              <div className={styles.nameContainer}>
                <p className={styles.fundName}>{name} </p>

                {!preview && (
                  <p className={styles.funderName}>{t('funderName', { name: funderName })}</p>
                )}
              </div>
            </Grid>
            <Grid container item className={classnames(styles.bottomRowContainer)}>
              <div
                className={classnames(
                  styles.bottomRowContainerLeft,
                  preview && styles.bottomRowPreview,
                )}
              >
                <FundInformation fundInformation={fundInformation} preview={preview} />
                {groupHasProjectMatched && (
                  <StatusChip
                    color="primary"
                    label={
                      <span className={styles.chipLabel}>
                        <RiCheckboxCircleFill size={16} />
                        <span>{t('applied')}</span>
                      </span>
                    }
                  />
                )}
              </div>
              <div>
                <Button buttonType="secondary" to={getFundPath(id)} className={styles.button}>
                  {t('view')}
                </Button>
              </div>
            </Grid>
          </Grid>

          {showGroupEligibilityData && (
            <Grid item>
              <p className={styles.notEligibleText}>
                <Trans
                  ns="fundingOpportunities"
                  i18nKey="not_eligible_text"
                  components={[
                    <span className={styles.notEligiblityTextHighlight} />,
                    <Link
                      primary
                      onClick={handleViewGroupEligibilityData}
                      underline
                      className={styles.notEligiblityLink}
                      stopPropagation
                    />,
                  ]}
                />
              </p>

              <Collapse in={groupEligibilityDataBreakdownVisible}>
                {!isGroupEligibilityForFundDataLoading ? (
                  <GroupEligibilityInformation
                    groupEligibilityForFundData={groupEligibilityForFundData}
                    fundLocation={fundLocation}
                    categoryIDs={categories}
                    groupTypes={group_types_to_support}
                  />
                ) : (
                  <Loader />
                )}
              </Collapse>
            </Grid>
          )}
        </Grid>
      </Tile>
    </Link>
  );
};
