import classnames from 'classnames';
import styles from './FundInformation.module.scss';

export interface FundInformationProps {
  fundInformation: { title: string; icon: JSX.Element; text: string | JSX.Element }[];
  preview?: boolean;
}

export const FundInformation = ({ fundInformation, preview }: FundInformationProps) => {
  return (
    <div className={styles.fundInformationContainer}>
      {fundInformation.map(({ title, icon, text }, index) => (
        <div
          className={classnames(
            styles.fundInformationItem,
            preview && styles.fundInformationItemPreview,
          )}
          key={index}
        >
          {title}
          <div className={styles.iconText}>
            {icon}
            {text}
          </div>
        </div>
      ))}
    </div>
  );
};
