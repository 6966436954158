import React from 'react';
import styles from './HelpCenterCard.module.scss';
import { Grid } from '@material-ui/core';
import { Button, Link, Tile } from '@Components/common';
import { Trans, useTranslation } from 'react-i18next';
import { RoutingPaths } from '@App/paths';
import { UserTypeEnum } from '@Types';

export interface HelpCenterCardProps {
  helpCenterURL: string;
  userType: UserTypeEnum;
}

export const HelpCenterCard = ({ helpCenterURL, userType }: HelpCenterCardProps) => {
  const { t } = useTranslation('dashboard');
  return (
    <Tile className={styles.container}>
      <Grid container direction="column">
        <Grid item className={styles.banner}>
          <img src="/assets/Dashboard/HelpCentreCard/header.svg" alt="" />
        </Grid>
        <Grid container direction="column" className={styles.textContainer} spacing={2}>
          <Grid item>
            <h3 className={styles.title}>{t('help_centre_title')}</h3>
          </Grid>
          <Grid item>
            {userType === UserTypeEnum.GROUP ? (
              t('help_centre_text_group')
            ) : (
              <Trans
                ns="dashboard"
                i18nKey="help_centre_text_funder"
                components={[<Link primary href={RoutingPaths.CONTACT} underline />]}
              />
            )}
          </Grid>
          <Grid item className={styles.buttonContainer}>
            <Button buttonType="secondary" to={helpCenterURL}>
              {t('help_centre')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Tile>
  );
};
